<template>
  <v-container>
    <h3>Playstation Network Credentials</h3>
    <v-spacer />
    <v-text-field
      label="Playstation Client ID"
      v-model="playstationnetworkClientID"
      :disabled="formDisabled()"
    />
    <v-text-field
      label="Client Secret"
      v-model="playstationnetworkClientSecret"
      :disabled="formDisabled()"
    />
  </v-container>
</template>

<script>
  export default {
    name: 'PlaystationClientForm',
    props: [
      'gameIdentityProviderID',
      'formData',
      'savedFormData',
      'updateInProgress',
    ],
    data() {
      return {
        playstationnetworkClientID: '',
        playstationnetworkClientSecret: '',
      };
    },
    mounted: function () {
      if (this.formData != '') {
        this.playstationnetworkClientID = this.formData.playstationnetworkClientID;
        this.playstationnetworkClientSecret = this.formData.playstationnetworkClientSecret;
      }
    },
    watch: {
      playstationnetworkClientID: {
        handler() {
          this.formUpdated();
        },
      },
      playstationnetworkClientSecret: {
        handler() {
          this.formUpdated();
        },
      },
    },
    methods: {
      formDisabled() {
        //Ensures that when an update is canceled, the form reverts to the saved data, but also that we don't save blank credentials
        if (this.gameIdentityProviderID !== '' && !this.updateInProgress) {
          this.playstationnetworkClientID = this.savedFormData.playstationnetworkClientID;
          this.playstationnetworkClientSecret = this.savedFormData.playstationnetworkClientSecret;
        }
        return this.gameIdentityProviderID !== '' && !this.updateInProgress;
      },
      formUpdated() {
        const credentials = {
          playstationnetworkClientID: this.playstationnetworkClientID,
          playstationnetworkClientSecret: this.playstationnetworkClientSecret,
        };
        this.$emit('formUpdated', credentials);
      },
    },
  };
</script>
